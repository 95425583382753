import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

export const hiddenTargetTypeOptions = ():Array<SelectOptionData> => {
    return [
        { 'label': 'hidden_target_type_return', 'value': HiddenTargetType.Return.toString() },
        { 'label': 'hidden_target_type_redirect', 'value': HiddenTargetType.Redirect.toString() },
        { 'label': 'hidden_target_type_message', 'value': HiddenTargetType.Message.toString() },
        { 'label': 'hidden_target_type_article', 'value': HiddenTargetType.Article.toString() }
    ]
}

export enum HiddenTargetType {
    Return = 'Return',
    Redirect = 'Redirect',
    Message = 'Message',
    Article = 'Article'
}
