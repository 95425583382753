import buttonView from './FormButton.vue'
import setupView from './FormButtonSetupView.vue'
import Button, { ButtonStruct, BaseButtonProps } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import { Guid } from 'guid-typescript'
import { cloneDeep } from 'lodash'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

type FormButtonDataType = {
    text: string
    key: string
}

export default class FormButton extends Button {
    name = 'FormButton'

    setupView = setupView
    buttonView = buttonView

    data: FormButtonDataType = this.getRawData()

    constructor(props: BaseButtonProps<{ getFormsFn: () => Array<SelectOptionData> }>) {
        super(props)
    }

    icon = '📎'

    getRawData(): FormButtonDataType {
        return {
            text: this.$i18n.t('initial_text_message_editor_buttons').toString(),
            key: '',
        }
    }

    create(): ButtonStruct {
        const struct = new FormButtonStruct({
            ...cloneDeep(this.data),
            guid: Guid.create().toString(),
            type: this.name
        })
        this.refreshData()

        return struct
    }
}

export class FormButtonStruct extends ButtonStruct {
    key: string

    constructor(props: FormButtonStruct) {
        super(props)

        this.key = props.key
    }
}
