import buttonView from './CheckButton.vue'
import setupView from './CheckButtonSetupView.vue'
import Button  from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'

export default class CheckButton extends Button {
    name = 'Check'

    setupView = setupView
    buttonView = buttonView

    data: { text: string } = this.getRawData()

    icon = '✔️'

    getRawData(): { text: string } {
        return { text: this.$i18n.t('initial_text_message_editor_buttons').toString() }
    }
}
