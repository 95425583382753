var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{attrs:{"slim":""}},[_c('div',{staticClass:"hidden-counted-message-button"},[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'text',
          'prefix': 'hidden_counted_message_button_',
          'validation': 'required',
          'saveFieldKey': 'text_hidden_button_click'
        },
      }}}),_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'emptyText',
          'prefix': 'hidden_counted_message_button_',
          'validation': 'required',
          'saveFieldKey': 'emptyText_hidden_button_click'
        },
      }}}),_c('number-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'limit',
          'prefix': 'hidden_counted_message_button_',
          'validation': 'required',
          'min': 0,
          'saveFieldKey': 'limit_hidden_button_click'
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'grouped',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'onlyUnique',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'stickyResponce',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          model: _vm.model,
          'key': 'popup',
          'prefix': 'hidden_counted_message_button_',
        },
      }}}),_c('div',{staticClass:"mx-2 my-5 relative"},[_c('field-title',{staticClass:"mb-2",attrs:{"title-key":"hidden_counted_message_button_target_value","required":true}}),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":"unsubscribedMessage","rules":"required","immediate":true}},[_c('a-textarea',{staticClass:"p-5",attrs:{"max-length":200,"rows":4},model:{value:(_vm.model.targetValue),callback:function ($$v) {_vm.$set(_vm.model, "targetValue", $$v)},expression:"model.targetValue"}}),_c('a-tag',{staticClass:"textarea-counter-tag absolute mr-0 rounded-none"},[_vm._v(" "+_vm._s(((_vm.model.targetValue.length) + " / 200"))+" ")])],1)],1),_c('div',{staticClass:"mx-2 my-5 relative"},[_c('field-title',{staticClass:"mb-2",attrs:{"title-key":"hidden_counted_message_button_target_value","required":true}}),_c('validation-provider',{staticClass:"mt-2",attrs:{"name":"afterTargetValue","rules":"required","immediate":true}},[_c('a-textarea',{staticClass:"p-5",attrs:{"max-length":200,"rows":4},model:{value:(_vm.model.afterTargetValue),callback:function ($$v) {_vm.$set(_vm.model, "afterTargetValue", $$v)},expression:"model.afterTargetValue"}}),_c('a-tag',{staticClass:"textarea-counter-tag absolute mr-0 rounded-none"},[_vm._v(" "+_vm._s(((_vm.model.afterTargetValue.length) + " / 200"))+" ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }