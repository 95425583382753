import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { AvailableButtonsTypes } from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/types'

import { Vue, Component } from 'vue-property-decorator'

import TextButton
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text'
import FingerDownReaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerDownReaction'
import Share
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Share'
import Reaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Reaction'
import PalmsReaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/PalmsReaction'
import FingerUpReaction
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FingerUpReaction'
import Link
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Link'
import { MultiMessageEditorWithMediaData } from 'piramis-base-components/src/components/MultiMessageEditorWithMedia/types'
import Text
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/Text'
import FlowFormButton
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/FlowFormButton'

import CommentsButton from '@/components/ProjectButtons/CommentsButton'
import HiddenCountedMessageButton
	from '@/components/ProjectButtons/HiddenCountedMessageButton'
import HiddenMessageButton from '@/components/ProjectButtons/HiddenMessageButton'
import SimpleHiddenMessageButton from '@/components/ProjectButtons/SimpleHiddenMessageButton'
import HiddenSubscriberLinkButton
	from '@/components/ProjectButtons/HiddenSubscriberLinkButton'

import HiddenCountedLinkButton from '@/components/ProjectButtons/HiddenCountedLinkButton'
import ShareTextButton from '@/components/ProjectButtons/ShareTextButton'
import RunFlowButton from '@/components/ProjectButtons/RunFlowButton'
import MetaDataService from '@/includes/services/MetaDataService'
import RunFlowNodeButton
	from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons/RunFlowNodeButton/index'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import { ForwarMessageActionMode } from '@/includes/types/Post.types'
import FormButton from '@/components/ProjectButtons/FormButton'
import Button
	from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'

type moreButtons = { moreSimpleButton?: Array<Button>, moreProButtons?: Array<Button> }

@Component({
	data() {
		return {
			StepUnit
		}
	}
})
export class InputSetups extends Vue {
	props = { i18n: this.$i18n }

	getDefaultButtons(moreButtons?: moreButtons): AvailableButtonsTypes {
		return [
			{
				name: this.$t('field_simple_buttons_title').toString(),
				buttons: [
					new Link(this.props),
					new Share(this.props),
					new SimpleHiddenMessageButton(this.props),
					new ShareTextButton(this.props),
					new Reaction(this.props),
					new PalmsReaction(this.props),
					new FingerUpReaction(this.props),
					new FingerDownReaction(this.props),
					...moreButtons && moreButtons.moreSimpleButton ? moreButtons.moreSimpleButton : [],
					this.formButtonButtonView,
				]
			},
			{
				name: this.$t('field_pro_buttons_title').toString(),
				buttons: [
					this.RunFlowButtonView,
					this.CommentsButtonView,
					new HiddenMessageButton(this.props),
					new HiddenCountedMessageButton(this.props),
					this.hiddenSubscriberLinkButtonView,
					this.hiddenCountedLinkButtonView,
					...moreButtons && moreButtons.moreProButtons ? moreButtons.moreProButtons : [],
				]
			}
		]
	}

	get defaultMessageEditorButtons(): AvailableButtonsTypes {
		return this.getDefaultButtons()
	}

	get flowButtons(): AvailableButtonsTypes {

		return [
			new RunFlowNodeButton(this.props),
			new Link(this.props),
			new Share(this.props),
			new FlowFormButton({ ...this.props, getForms: () => this.getFormsSelectOptions(), createFormButtonCallback: () => this.formCreateRoute }),
			this.RunFlowButtonView,
		]
	}

	get formCreateRoute() {
		return this.$router.push({
			name: 'Full_form',
			params: {
				actionType: BaseItemViewAction.New,
			}
		})
	}

	get textButton(): AvailableButtonsTypes {
		return [
			new TextButton(this.props),
			...this.$store.state.boardsState.activeBoard && this.$store.state.boardsState.activeBoard.features_version >= 1000 ? [
				this.formButtonButtonView,
			] : [],
		]
	}

	get pmButtons(): AvailableButtonsTypes {
		return [
			this.RunFlowButtonView,
			new Share(this.props),
			new Link(this.props),
			new ShareTextButton(this.props),
			...this.$store.state.boardsState.activeBoard && this.$store.state.boardsState.activeBoard.features_version >= 1000 ? [
				this.formButtonButtonView,
			] : [],
		]
	}

	get RunFlowButtonView() {
		return new RunFlowButton({
			...this.props,
			getFlowsOptions: this.getFlowSelectOptions,
			getUtmSourcesOptions: this.getUtmSelectOptions,
			...!this.$store.getters.hasBoardLicense ? {
				tags: () => [ {
					text: 'Prime',
					color: 'rgba(var(--a-danger), 1)'
				} ]
			} : {}
		})
	}

	getFlowSelectOptions(): Promise<Array<SelectOptionData>> {
		return new Promise(resolve => {
			if (!this.$store.state.flowsState.flows) {
				this.$store.dispatch('requestFlows', { board_key: this.$store.state.boardsState.activeBoard?.board })
					.then(() => {
						resolve(this.$store.getters.flowOptions)
						return this.$store.getters.flowOptions
					})
			} else {
				resolve(this.$store.getters.flowOptions)
			}
		})
	}

	getUtmSelectOptions(): Promise<Array<SelectOptionData>> {
		return new Promise(resolve => {
			MetaDataService.getUtmSources('tg', { board_key: this.$store.state.boardsState.activeBoard?.board })
				.then(({ items }) => {
					resolve(items.map(utm => ({
						label: utm.value,
						value: utm.id
					})))
				})
		})
	}

	getFormsSelectOptions(): Array<SelectOptionData> {
		return this.$store.getters.formsSelectOptions
	}

	get CommentsButtonView() {
		return new CommentsButton({ botName: this.$store.state.boardsState.activeBoard?.title, ...this.props })
	}

	get hiddenSubscriberLinkButtonView(): HiddenSubscriberLinkButton {
		return new HiddenSubscriberLinkButton({ getArticlesFn: this.getArticlesSelectOptions, ...this.props })
	}

	get hiddenCountedLinkButtonView(): HiddenCountedLinkButton {
		return new HiddenCountedLinkButton({ getArticlesFn: this.getArticlesSelectOptions, ...this.props })
	}

	get formButtonButtonView(): FormButton {
		return new FormButton({
			...this.props,
			getFormsFn: this.getFormsSelectOptions
		})
	}

	async getArticlesSelectOptions(): Promise<Array<SelectOptionData>> {
		if (this.$store.getters.hasArticles && this.$store.state.articlesState.articles === null) {
			await this.$store.dispatch('requestArticles')
		 }

		return this.$store.getters.articlesOptions
	}

	get defaultChatMessageEditorButtons() {
		return [ Text ]
	}

	channelConfigSetup(args: FieldData): FieldData {
		args.model = this.$store.state.channelsState.activeChannel.config
		if (args.setter === undefined) {
			args.setter = (value: any): void => {
				this.$store.commit('pi/EXEC', {
					'key': args.key,
					'fn': () => {
						this.$set(args.model, args.key as string, value)
					},
				})
			}
		}
		return args
	}

	modelSetter(args: FieldData): FieldData {
		if ([ 'postfix_message', 'prefix_message', 'variants', 'message' ].includes(args.key) && !(args as FieldData & MultiMessageEditorWithMediaData).settingsStructure) {
			(args as FieldData & MultiMessageEditorWithMediaData).settingsStructure = {
				'disable_link_preview': false,
				'disable_notify': false,
				'pin': false,
				'remove_previous': false,
			}
		}

		args.setter = (value: any) => {
			if (value !== undefined) {
				this.$set(args.model, args.key, value)
			}
		}
		return args
	}

	get forwardMessageActionModeOptions(): Array<SelectOption> {
		return Object.values(ForwarMessageActionMode).map(mode => ({
			label: this.$t(`mode_option_${ mode.toLowerCase() }`),
			value: mode
		}))
	}
}
