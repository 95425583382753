





























































































































































































































import CheckButton from '@/components/ProjectButtons/Check'
import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '@/components/EditorButtonsHelpView.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Mixins } from 'vue-property-decorator'
import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { InviteLink, InviteLinkApproveType } from '@/components/InviteLinks/types'
import Picker from 'piramis-base-components/src/components/Picker/Picker.vue'
import { PickerFormat } from 'piramis-base-components/src/components/Picker/types'
import ActivationActionData
  from 'piramis-base-components/src/components/BotFeedback/PrivateMessageSettings/components/ActivationActionData.vue'
import { UserActivationActionType } from '@/includes/types/PmConfig.types'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    ConfigField,
    Picker,
    ActivationActionData,
  },
  data() {
    return {
      EditorButtonsHelpView,
      InviteLinkApproveType,
      PickerFormat,
      CheckButton,
    }
  },
})
export default class LinkPage extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  isLoading = false

  linkModel: InviteLink = {
    title: '',
    tags: [],
    config: {
      approve: InviteLinkApproveType.None,
      disable_auto_attack: false,
      expire: '',
      limit: 0,
      price: 0,
      message: [ {
        attachments: [],
        text: '',
        buttons: [],
        remove_previous: false,
        pin: false,
        disable_link_preview: false,
        disable_notify: false,
      } ],
      user_accept_action: {
        type: UserActivationActionType.Message,
        variants: []
      },
      user_reject_action: {
        type: UserActivationActionType.Message,
        variants: []
      },
    }
  }

  get approveTypeOptions(): Array<SelectOptionData> {
    return Object.values(InviteLinkApproveType).map((type) => {
      return {
        label: this.$t(`link_approve_type_${ type.toLowerCase() }`).toString(),
        value: type
      }
    })
  }

  get isSaveButtonDisabled():boolean {
    const approve = this.linkModel.config.approve

    if (approve === InviteLinkApproveType.None) {
      return !this.linkModel.title
    } else {
      const message = this.linkModel.config.message

      return !(this.linkModel.title && message && message!.length && message![0].text.trim().length)
    }
  }

  get linkActionType(): 'new' | 'edit' {
    return this.$route.params.actionType.toString() as 'new' | 'edit'
  }

  goToRemovingUsers(): void {
    this.$router.push({
      name: 'Channel_protection',
      params: this.$route.params,
      query: {
        pressLink: this.$route.query.linkKey
      }
    })
  }

  handleLinkSaveButtonClick(): void {
    const approve = this.linkModel.config.approve

    if (approve === InviteLinkApproveType.None) {
      delete this.linkModel.config.message
      delete this.linkModel.config.user_reject_action
      delete this.linkModel.config.user_accept_action
    } else if (approve === InviteLinkApproveType.Auto) {
      delete this.linkModel.config.user_reject_action
    }

    if (this.linkActionType === 'new') {
      this.createInviteLink()
    }
    if (this.linkActionType === 'edit') {
      this.editInviteLink()
    }
  }

  editInviteLink(): void {
    this.$store.dispatch('editInviteLink', { link: this.linkModel, link_key: this.$route.query.linkKey })
      .finally(() => this.gotoInviteLinksListPage())
  }

  createInviteLink(): void {
    this.$store.dispatch('createInviteLink', this.linkModel)
      .finally(() => this.gotoInviteLinksListPage())
  }

  gotoInviteLinksListPage(): void {
    this.$router.push({ name: 'invite_links_list' })
  }

  approveSetter(arg: FieldData): FieldData {
    arg.setter = (val) => {
      if (val !== InviteLinkApproveType.None) {
        this.linkModel.config.limit = 0

        if (!this.linkModel.config.message) {
          this.linkModel.config.message = [ {
            attachments: [],
            text: '',
            buttons: [],
            remove_previous: false,
            pin: false,
            disable_link_preview: false,
            disable_notify: false,
          } ]
        }
      } else {
        delete this.linkModel.config.message
      }

      if (val === InviteLinkApproveType.Auto) {
        this.linkModel.config.user_reject_action = null
      }

      this.linkModel.config.approve = val
    }
    return arg
  }

  async created(): Promise<void> {
    this.isLoading = true
    if (!this.$store.state.flowsState.flows) {
      await this.$store.dispatch('requestFlows')
    }

    if (!this.$store.state.inviteLinksState.links) {
      await this.$store.dispatch('getInviteLinks')
    }

    if (this.$route.params.actionType === 'edit' && this.$route.query.linkKey) {
      const link = await this.$store.dispatch('getInviteLinkModel', this.$route.query.linkKey)

      if (link) {
        this.linkModel = cloneDeep(link)
      }
    }

    this.isLoading = false
  }
}
