import buttonView from './HiddenMessageButton.vue'
import setupView from './HiddenMessageButtonSetupView.vue'
import Button, { ButtonStruct } from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";

type HiddenMessageButtonDataType = {
  text: string
  subscribedMessage: string
  notEnoughSubscribedMessage: string
  unsubscribedMessage: string
  daysCount: 31,
}

export default class HiddenMessageButton extends Button {
  name = 'HiddenMessageButton'

  setupView = setupView
  buttonView = buttonView

  data: HiddenMessageButtonDataType = this.getRawData()

  structBuilder = HiddenMessageButtonStruct

  icon = '🌙'

  getRawData(): HiddenMessageButtonDataType {
    return {
      text: '',
      subscribedMessage: this.$i18n.t('initial_text_message_editor_hidden_message_button_subscribed').toString(),
      notEnoughSubscribedMessage: this.$i18n.t('initial_text_message_editor_hidden_message_button_not_enough_subscribed').toString(),
      unsubscribedMessage: this.$i18n.t('initial_text_message_editor_hidden_message_button_unsubscribed').toString(),
      daysCount: 31,
    }
  }
}

export class HiddenMessageButtonStruct extends ButtonStruct {
  subscribedMessage: string
  notEnoughSubscribedMessage: string
  unsubscribedMessage: string
  daysCount: number

  constructor(struct: HiddenMessageButtonStruct) {
    super(struct)

    const { subscribedMessage, notEnoughSubscribedMessage, unsubscribedMessage, daysCount } = struct

    this.subscribedMessage = subscribedMessage
    this.notEnoughSubscribedMessage = notEnoughSubscribedMessage
    this.unsubscribedMessage = unsubscribedMessage
    this.daysCount = daysCount
  }
}
