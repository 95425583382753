














































































import Editor from 'piramis-base-components/src/components/Editor/Editor.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'
import { SimpleHiddenMessageButtonStruct } from '@/components/ProjectButtons/SimpleHiddenMessageButton/index'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Editor
  },
})
export default class SimpleHiddenMessageButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: SimpleHiddenMessageButtonStruct

  currentTab = 1
}
