import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'

export type UserActivationAction = UserActivationActionFlow | UserActivationActionMessage

export type PmConfig = {
    type: 'common',
    initial_message: Array<MessageEditorWithMediaData> | null
    feedback_group_id: number | null
    first_activation_action: UserActivationAction | null
    start_activation_action: UserActivationAction | null
    default_activation_action: UserActivationAction | null
    user_auto_reply_actions: Array<UserAutoReplyAction> | null
    commands: Array<UserAutoReplyAction> | null
}

export enum AutoReplyConditionType {
    Equals = 'Equals',
    Contains = 'Contains',
    StartsWith = 'StartsWith',
    EndsWith = 'EndsWith',
    WordEquals = 'WordEquals',
}

export type EqualsCondition = { type: AutoReplyConditionType.Equals } & AutoReplyConditionBaseFields
export type ContainsCondition = { type: AutoReplyConditionType.Contains } & AutoReplyConditionBaseFields
export type StartsWithCondition = { type: AutoReplyConditionType.StartsWith } & AutoReplyConditionBaseFields
export type EndsWithCondition = { type: AutoReplyConditionType.EndsWith } & AutoReplyConditionBaseFields
export type WordEqualsCondition = { type: AutoReplyConditionType.WordEquals } & AutoReplyConditionBaseFields

export type AutoReplyCondition =
    EqualsCondition
    | ContainsCondition
    | StartsWithCondition
    | EndsWithCondition
    | WordEqualsCondition

export type AutoReplyConditionBaseFields = {
    values: Array<string>
    invert: boolean
}

export type UserAutoReplyAction = {
    conditions: Array<Array<AutoReplyCondition>>
    action: UserActivationActionFlow | UserActivationActionMessage
    guid:string
}

export enum UserActivationActionType {
    Flow = 'Flow',
    Message = 'Message'
}

export type UserActivationActionFlow = {
    type: UserActivationActionType.Flow,
    flow: string
    // temp: default 0
    output: number
}

export type UserActivationActionMessage = {
    type: UserActivationActionType.Message
    variants: Array<MessageEditorWithMediaData>
}
