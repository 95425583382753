import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { UserActivationActionFlow, UserActivationActionMessage } from '@/includes/types/PmConfig.types'

export type InviteLink = {
	title: string
	tags: Array<string>
	config: {
		price: number
		limit: number
		expire?: string
		disable_auto_attack: boolean
		approve: InviteLinkApproveType
		message?: Array<MessageEditorWithMediaData>
		user_accept_action?: UserActivationActionFlow | UserActivationActionMessage | null
		user_reject_action?: UserActivationActionFlow | UserActivationActionMessage | null
	}
}

export type ListInviteLinkModel = {
	key: string
	link: string
	enter: number
	leave: number
	kick: number
	ignore_invite_protection: boolean
} & InviteLink

export type ChartMetric = {
	name: string,
	data: Array<number>
}

export type InviteLinkChart = {
	name?: string,
	title: string,
	series: Array<ChartMetric>
}

export enum InviteLinkApproveType {
	None = 'None',
	Manual = 'Manual',
	Auto = 'Auto',
}
